<template lang="pug">
.Agendar
  figure.container-doc
  .overlayau(v-if="showModal")
  .modalau(v-if="showModal")
    button(@click="showModal = false").close-btn
      svg(width='70' height='70' viewbox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg')
        rect(x='1' y='1' width='68' height='68' rx='34' fill='white' stroke='#F2F2F2')
        path(fill-rule='evenodd' clip-rule='evenodd' d='M22.2346 22.2425C23.3498 21.1242 25.1592 21.1228 26.2761 22.2393L34.9993 30.9595L43.7222 22.2395C44.8391 21.1229 46.6486 21.1244 47.7637 22.2427C48.8789 23.361 48.8775 25.1726 47.7605 26.2892L39.044 35.0028L47.7605 43.7165C48.8775 44.833 48.8789 46.6447 47.7637 47.763C46.6486 48.8813 44.8391 48.8827 43.7222 47.7662L34.9993 39.0462L26.2761 47.7664C25.1592 48.8829 23.3498 48.8815 22.2346 47.7632C21.1194 46.6449 21.1209 44.8332 22.2378 43.7167L30.9545 35.0028L22.2378 26.289C21.1209 25.1725 21.1194 23.3608 22.2346 22.2425Z' fill='#DEDEDE')
    h2.blue-title Confirma tu
    h2.blue-title número de celular
    p.mt-5.text-desc Indica el número de teléfono para la confirmación de la cita.
    .card-desc
      p sdds
    .input-text
      p.label-text Número de teléfono*
      input.Input-text(
        style="margin-bottom: 15px !important; width: 100%"
        type="text",
        v-model="models.NumeroIdentificacion",
        min="0"
        placeholder="Ingresa tu número",
        required,
        autocomplete="off"
      )#cedula_number
    button(@click="onShowModal").text-center.buttonService.mt-3
      span Confirmar el número teléfonico
  .card-content
    h2.blue-title Selecciona el tipo e ingresa
    h2.blue-title el número de documento
    .inputs
      .select-input
        p.label-text Tipo de documento
        .form-select-container.select--Input-text.mb-2
          b-form-select.Input-text(
            v-model="models.TipoIdentificacion",
            :options="options",
            size="sm",
            autocomplete="off",
            required,
          )#formSelect
          svg(@click="onFocusSelect").mr-3(width='30' height='29' viewbox='0 0 30 29' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M15.0001 1.16699C17.6371 1.16699 20.215 1.92946 22.4077 3.35791C24.6003 4.78637 26.3093 6.81667 27.3184 9.19211C28.3276 11.5675 28.5916 14.1814 28.0772 16.7031C27.5627 19.2249 26.2928 21.5413 24.4281 23.3594C22.5634 25.1774 20.1877 26.4156 17.6013 26.9172C15.0149 27.4188 12.334 27.1613 9.89762 26.1774C7.46128 25.1935 5.3789 23.5272 3.91382 21.3894C2.44873 19.2516 1.66675 16.7382 1.66675 14.167C1.66675 10.7192 3.0715 7.41259 5.57198 4.97462C8.07246 2.53665 11.4638 1.16699 15.0001 1.16699Z' stroke='#788591' stroke-width='2.025' stroke-linecap='round' stroke-linejoin='round')
            path(d='M15.0002 16.7656L18.5624 13.2925' stroke='#788591' stroke-width='2.025' stroke-linecap='round' stroke-linejoin='round')
            path(d='M11.4377 13.2925L14.9999 16.7656' stroke='#788591' stroke-width='2.025' stroke-linecap='round' stroke-linejoin='round')
          .svgBackground
      .input-text
        p.label-text Número de documento
        input.Input-text(
          style="margin-bottom: 15px !important; width: 100%"
          type="text",
          v-model="models.NumeroIdentificacion",
          min="0"
          placeholder="Ej: 105456783",
          required,
          autocomplete="off"
        )#cedula_number
    button(style="max-width: 504px" @click="onClickQuery").text-center.buttonService.mt-3
      span Consultar mis datos
</template>

<script>
// import { mapState } from "vuex";
import { encrypt as encryptAes, encodeb64 } from "./../../helpers/fsfbau"

export default {
  name: "Agendar",
  data() {
    return {
      models: {
        NumeroIdentificacion: "",
        TipoIdentificacion: null
      },
      showModal: false,
      options: [
        { value: null, text: 'Seleccione una opción', disabled: true },
        { value: 1010, text: "Registro civil" },
        { value: 2, text: "Tarjeta de identidad" },
        { value: 1, text: "Cédula de ciudadanía" },
        { value: 4, text: "Cédula de extranjería" },
        { value: 1014, text: "NIT" },
        { value: 5, text: "Menor sin identificacion" },
        { value: 3, text: "Pasaporte" },
        { value: 6, text: "Adulto sin identificacion" },
        { value: 1015, text: "NUIP" },
        { value: 1012, text: "Carné diplomático" },
        { value: 1016, text: "Salvoconducto" }
      ]
    };
  },
  methods: {
    onFocusSelect() {
      document.getElementById("formSelect").focus();
    },
    onShowModal() {
      this.showModal = true;      
    },
    onClickQuery() {
      let config = {
        "clientId": "FSFB",
        "patientDocumentType": this.models.TipoIdentificacion,
        "patientDocument": this.models.NumeroIdentificacion
      }
      let result = encryptAes(config);
      window.location.href = `https://app-scheduler-poc-fsfb-001.azurewebsites.net/paciente?embebedData=${encodeb64(result)}`
    }
  }
};
</script>

<style lang="scss" scoped>
.card-content {
  background-color: white;
  border-radius: 20px;
  border: none;
  width: 100%;
  padding: 3rem 2rem;
  box-shadow: 0px 40px 50px -30px #0000001a;
}

.blue-title {
  color: #001698;
  font-weight: 700;
  line-height: 49.22px;
  font-size: 42px;
}

.blue-title:last-of-type {
  margin-bottom: 3.5rem;
}

.inputs {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
}

.select-input,
.input-text {
  flex: 1;
}

select.Input-text {
  background: none !important;
  height: 73.3px;
}

.Input-text {
  background: #ffffff !important;
  border: 2.7px solid #dedede !important;
  border-radius: 20px !important;
  box-sizing: border-box;
  font-size: 24px;
  padding: 1rem 2rem;
}

.Input-text::placeholder {
  color: #dedede !important;
}

.select--Input-text .svgBackground {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: none !important;
  border-radius: 20px !important;
}

.form-select-container {
  position: relative;
}

.form-select-container svg {
  background: #ffffff;
  right: 13px;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.Content {
  position: relative;
  width: 100%;
}

.CurrentUser {
  margin: auto;
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
  table-layout: fixed;
  position: relative;
}

.box-dk {
  margin: auto;
  text-align: center;
  line-height: 1;
  font-size: 1.3rem;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 12300000;
  padding: 1rem;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 16px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.button {
  background-color: #0057a0;
  /* color: #fff; */
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

#d-flex span svg {
  width: 20px;
  height: 20px;
  /* fill: white; */
}

.big_box {
  position: relative;
}

.dk-link {
  outline: none;
  border: none;
  appearance: none;
  text-decoration: none;
  background: none;
  /* color: #6565; */
  color: #6558f5;
  display: block;
  text-align: center;
  font-size: 1.2rem;
  margin: 93px auto;
}

.dk-link:hover {
  text-decoration: underline;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.disabled {
  cursor: not-allowed;
  background: #0058a0a4;
}

.disabled:hover {
  background: #0058a0cb;
}

.dk-pos-dk {
  position: absolute;
  top: 30%;
  left: 0px;
  font-weight: bold;
  transition: 1s;
  right: 0px;
}

.Input-text::placeholder {
  color: rgb(0 0 0) !important;
}

.form-select-container {
  position: relative;
}

.h1 {
  font-size: 2.8rem;
}

.buttonService {
  font-family: "Gilroy-Semibold";
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.text-start {
  text-align: start;
}

.buttonService:disabled {
  background-color: #f2f2f2;
  color: #dedede;
  filter: none;
}

.buttonService.outlineService {
  border: 2.05347px solid #788591;
  box-sizing: border-box;
  color: #788591;
  background: none;
}

.label-text {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  color: #59616a;
  // color: #5ac3f0;
}

.containerImg-img {
  object-fit: cover;
  max-height: calc(100vh - 112.06px);
}

.box-tape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    359.49deg,
    #202d78 2.94%,
    rgba(32, 40, 87, 0.661458) 56.73%,
    rgba(33, 30, 23, 0) 99.46%
  );
}

.flex-1 {
  flex: 1;
}

.logoFSFBTransparent {
  position: absolute;
  right: 0;
  bottom: 126px;
  z-index: 2;
}

.containerForm {
  max-width: 476px;
  margin: auto;
  display: block;
}

.fonterCurrentUser {
  max-width: 476px;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #788591;
}

.boxText {
  position: absolute;
  bottom: 163px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  max-width: fit-content;
}

.welcomeText {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 55px;
  color: #ffffff;
}

.box.box2 {
  padding: 0;
  max-width: 799px;
}
.Input-text::placeholder {
  color: #dedede !important;
}

.error {
  color: #e66921;
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

.overlayau {
  position: fixed;
  inset: 0;
  background: linear-gradient(
    359.49deg,
    #202d78 2.94%,
    rgba(32, 40, 87, 0.661458) 56.73%,
    rgba(33, 30, 23, 0) 99.46%
  );
  z-index: 99;
}

.modalau {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 48px;
  background-color: white;
  z-index: 100;
  width: 624px;
  padding: 2rem;
}

.modalau h2.blue-title {
  margin-bottom: 0;
}

.text-desc {
  color: #788591;
  font-size: 28px;
  line-height: 32.81px;
}

.close-btn {
  position: absolute;
  right: 2rem;
  top: 2rem;
  background: none;
  border: none;
}
</style>
