<template lang="pug">
.Home
  figure.container-doc
  .content
    h2.title.pb-5
      strong ¡Hola!
      span.ml-3 ¿Qué te gustaría hacer hoy?
    ul.list-cards.pt-3
      li.card
        .svg-container
          svg(width='48' height='48' viewbox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M24 35.9907C24.0018 38.3649 23.2994 40.6862 21.9815 42.661C20.6636 44.6359 18.7896 46.1754 16.5965 47.0848C14.4034 47.9942 11.9898 48.2326 9.6612 47.7699C7.33256 47.3071 5.19351 46.1641 3.51472 44.4853C1.83593 42.8065 0.692855 40.6674 0.230129 38.3388C-0.232597 36.0102 0.00582534 33.5966 0.915229 31.4035C1.82463 29.2104 3.36414 27.3364 5.33895 26.0185C7.31377 24.7006 9.63513 23.9982 12.0093 24C15.1887 24.0025 18.2371 25.2666 20.4853 27.5147C22.7334 29.7629 23.9975 32.8113 24 35.9907Z' fill='#E99287')
            path(d='M14.3783 3V8.71771M32.6356 3V8.71771M4.01428 15.5132H43M9.15231 5.41011H37.7762C39.1389 5.41011 40.4457 5.97623 41.4093 6.98395C42.3728 7.99167 42.9142 9.35842 42.9142 10.7836V39.6116C42.9142 41.0407 42.3713 42.4112 41.4051 43.4218C40.4388 44.4323 39.1283 45 37.7619 45H9.13798C7.7753 45 6.46843 44.4339 5.50488 43.4262C4.54132 42.4184 4 41.0517 4 39.6266V10.7836C4 10.0766 4.13338 9.37668 4.39248 8.72376C4.65158 8.07085 5.03132 7.47782 5.50994 6.97865C5.98857 6.47949 6.55666 6.08399 7.18168 5.81483C7.80671 5.54567 8.47637 5.40814 9.15231 5.41011Z' stroke='#001698' stroke-width='2.2' stroke-linecap='round' stroke-linejoin='round')
        h3.blue-title Agenda tus citas
        div(style="padding: 2rem; padding-bottom: 2rem")
          button(
            @click="onGoAgendar"
          ).text-center.buttonService.mt-3
            span
              | Agendar mi cita
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Home",
  methods: {
    ...mapActions({
      goToView: "virtualrowFSFBAU/goToView"
    }),
    onGoAgendar() {
      this.goToView({ view: "Agendar" });
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: white;
  border-radius: 20px;
  border: none;
  max-width: 400px;
  box-shadow: 0px 40px 50px -30px #0000001a;
}

.buttonService {
  font-family: "Gilroy-Semibold";
  width: 100%;
  padding: 1.5rem 2rem;
  border: 2.05347px solid #009dac;
  box-sizing: border-box;
  color: #009dac;
  background: none;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  outline: none;
  text-align: center;
  background-color: white;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.svg-container {
  width: 100%;
  max-width: 100px;
  height: 100px;
  background-color: #f2f4f8;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}

.blue-title {
  color: #000F65;
  font-weight: 700;
  margin-left: 2rem;
}

.title {
  display: flex;
  align-items: center;
}

.title span {
  color: #788591;
  font-size: 38px;
  font-weight: 100;
}

.title strong {
  font-size: 59px;
  line-height: 69px;
  color: #001698;
}
</style>
