<template lang="pug">
.MainView.box_size
  Header(
    class="atrilHeader"
  )
  Home(v-if="view === 'Home'")
  Agendar(v-else-if="view === 'Agendar'")
</template>

<script>
import { mapState } from "vuex";

import Header from "../virtualrowFSFB/components/Header";
import Home from "./Home.vue";
import Agendar from "./Agendar.vue";

export default {
  name: "virtualrowFSFBAU",
  components: {
    Header,
    Home,
    Agendar
  },
  computed: {
    ...mapState({
      view: state => state.virtualrowFSFBAU.view
    })
  }
};
</script>

<style lang="scss" scoped></style>
