import CryptoJS  from "crypto-js";

const secret = "1FA6B99564ECD65C"

export const encrypt = (msg) => {
  let _msg = msg
  if(typeof msg == "object") {
    _msg = JSON.stringify(msg);
  }
  var encrypted = CryptoJS.AES.encrypt(_msg, secret);
  return encrypted.toString();
}

export const decrypt = (_msg) => {
  var encrypted = CryptoJS.AES.encrypt(_msg, secret);
  let _result = encrypted;
  try {
    _result = JSON.parse(encrypted.toString())
  } catch (error) {
    console.log(error)
  }
  return _result;
}

export const encodeb64 = (_msg) => {
  const encodedWord = CryptoJS.enc.Utf8.parse(_msg); // encodedWord Array object
  const encoded = CryptoJS.enc.Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
  return encoded;
}

export const decodeb64 = (_msg) => {
  const encodedWord = CryptoJS.enc.Base64.parse(_msg); // encodedWord via Base64.parse()
  const decoded = CryptoJS.enc.Utf8.stringify(encodedWord); // decode encodedWord via Utf8.stringify() '75322541'
  return decoded;
}

